#services {
  text-align: center;
}

.services-grid {
  padding: 56px 0;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  width: 100%;
}
.services-grid > * {
  padding: 24px 16px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  width: 100%;
  /* min-width: 200px; */
  /* min-height: 156px; */
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  display: block;
  opacity: 0;
  animation: card-up 0.3s forwards cubic-bezier(0.4, 0, 0.2, 1);

  text-align: left;
  display: grid;
  grid-column-gap: 24px;
  grid-template-rows: 42px auto;
  grid-template-areas:
    "icon title"
    "icon text";
}

.services-grid > *:nth-child(1) {
  animation-delay: 0;
}
.services-grid > *:nth-child(2) {
  animation-delay: 75ms;
}
.services-grid > *:nth-child(3) {
  animation-delay: 120ms;
}
.services-grid > *:nth-child(4) {
  animation-delay: 150ms;
}
.services-grid > *:nth-child(5) {
  animation-delay: 162ms;
}
.services-grid > *:nth-child(6) {
  animation-delay: 168ms;
}

@keyframes card-up {
  from {
    transform: translateY(36px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.services-grid > *:hover {
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.3);
}
div.services-svg-wrap {
  grid-area: icon;
  transition: 0.1s ease-in-out;
}
div.services-svg-wrap > * {
  width: auto;
  padding: 12px;
  border-radius: 100%;
  background: #f0f2f3;
  display: block;
  transition: 0.1s ease-in-out;
}

.services-grid > *:hover div.services-svg-wrap > * {
  background: rgba(43, 71, 255, 0.1);
}

.services-grid > * svg {
  width: 32px;
  height: 32px;
  display: block;
  transition: 0.1s ease-in-out;
}
.services-grid > * svg path {
  fill: #9da5af;
  transition: 0.1s ease-in-out;
}

.services-grid > * h1 {
  color: #06152c;
  margin: 0;
}
.services-grid > * p {
  margin: 0;
}

.services-more-button {
  background: #2a46ff;
  color: white;
  min-height: 56px;
  margin-bottom: 32px;
}

.active-service {
  color: rgb(42, 70, 255);
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 0 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(157, 165, 175, 0.3);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgb(42, 70, 255);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
a.service-card {
  text-decoration: none;
}
.service-card > * {
  animation-delay: 100ms;
  animation: card-up 0.3s forwards cubic-bezier(0.4, 0, 0.2, 1);
}

.service-card:hover svg path {
  fill: #2a46ff;
}

@media only screen and (min-width: 600px) {
  .services-grid {
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
  .services-grid > * {
    text-align: center;
    display: block;
    padding: 24px;
    border-radius: 20px;
    min-width: 200px;
    min-height: 156px;
  }
  .services-grid > * svg {
    width: 48px;
    height: 48px;
  }
  .services-grid > * h1 {
    margin: 20px 0;
  }
  .services-grid > * p {
    margin: 16px;
  }
}

@media only screen and (min-width: 1020px) {
  .services-grid {
    padding: 56px 0;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .services-grid > * p {
    margin: 18px;
  }
}
