.slick-list {
  overflow: visible;
}

/* .slick-slider .slick-track {
  border: 1px solid white;
}
.slick-slider .slick-track:hover {
  border: 1px solid red;
}
.slick-slider .slick-track > *:hoever {
  animation-play-state: paused;
} */

/* .slick-slider button {
  display: none !important;
} */

.slick-slider .slick-track > * {
  margin-left: 16px;
}

@media only screen and (min-width: 600px) {
  .slick-slider .slick-track > * {
    margin-left: 24px;
  }
  /* .slick-slider button {
    z-index: 90;
    display: block !important;
  } */
  .slick-slider:hover {
    /* border: 1px solid red; */
  }
  /* .slick-slider:hover .slick-slider button {
    border: 1px solid red;
  } */
}
