.header-clip-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(auto, auto, auto, auto);
}
header {
  top: 0;
  left: 0;
  height: 56px;
  width: 100%;
  z-index: 100;
  position: fixed;

  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(30px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
section.blue-section header {
  background: rgba(51, 79, 255, 0.3);
  backdrop-filter: blur(30px);
  border-bottom: 1px solid rgba(51, 79, 255, 0.1);
}

.header-content-wrap {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  overflow: hidden;
}

section header nav a {
  text-decoration: none;
  color: #2a46ff;
  font-weight: 600;
  padding: 12px 24px;
  transition: 0.05s ease-in-out;
}

section.blue-section div header nav a {
  color: white;
}
section header nav a:hover {
  opacity: 0.5;
}

section header #header-contact-button {
  background: #2a46ff;
  color: white;
  padding: 8px;
}

section header #header-contact-button svg {
  height: 24px;
  width: 24px;
  margin: 0;
  display: block;
}
section header #header-contact-button div {
  display: none;
}

section.blue-section header #header-contact-button {
  background: white;
  color: #2a46ff;
}
.header-nav-underline {
  background: #2a46ff;
  height: 4px;
  position: absolute;
  bottom: 2px;
  border-radius: 2px;
  transition: 0.2s ease-in-out;
  margin-bottom: -12px;
}
.header-nav-underline.active-tab {
  /* opacity: 1; */
  margin-bottom: 0;
}

section.blue-section .header-nav-underline {
  background: white;
}

/* Tablet---- */
@media only screen and (min-width: 700px) {
  section header #header-contact-button {
    padding: 10px 25px;
  }
  section header #header-contact-button svg {
    display: none;
  }
  section header #header-contact-button div {
    display: block;
  }
  .header-content-wrap {
    max-width: 800px;
    padding: 0 16px;
  }
}
/* Desktop---- */
@media only screen and (min-width: 900px) {
  .header-content-wrap {
    max-width: 1400px;
    padding: 0 36px;
  }
}
