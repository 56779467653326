.card-row-wrapper {
  margin-bottom: 24px;
  position: relative;
  display: block;
  box-sizing: border-box;
}
.card-row-wrapper > * {
  /* border: 1px solid red; */
}
.carosel {
  display: flex;
  align-items: center;
  grid-gap: 24px;
}
.carosel > * {
  flex-shrink: 0;
}

.carosel:hover .portfolio-card {
  animation-play-state: paused;
}

.carosel > * {
  animation: 6s infinite forwards car-fwd linear;
}
.carosel.car-rev > * {
  animation: 6s infinite forwards car-rev linear;
}

@keyframes car-fwd {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-224px);
  }
}

@keyframes car-rev {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(224px);
  }
}

.BrainhubCarousel__container,
.BrainhubCarousel,
.BrainhubCarousel__trackContainer,
.BrainhubCarousel__track {
  overflow: visible !important;
}
