#contact form {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  margin-top: 24px;
  width: 100%;
  box-sizing: border-box;
  color: #06152c;
}
#contact form a {
  color: rgb(42, 70, 255);
}
#contact form button {
  background: #2a46ff;
  color: white;
}

#contact form input,
#contact form textarea {
  padding: 12px 8px;
  border-radius: 10px;
  background: #f4f4f5;
  outline: none;
  border: none;
  color: #06152c;
  transition: 0.2s ease-in-out;
  border: 2px solid white;
  width: 100%;
  box-sizing: border-box;
}
#contact form input:hover,
#contact form textarea:hover {
  background: #ecedee;
}
#contact form textarea {
  min-height: 200px;
  resize: vertical;
}

#contact form input:placeholder,
#contact form textarea:placeholder {
  color: #838a96;
}

#contact form input:valid,
#contact form textarea:valid {
  border: 2px solid #6fcf97;
}

.sent-animation {
  animation: fade-out 2s ease-in-out forwards;
  animation-delay: 8s;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.subscribe-check-wrap {
  cursor: pointer;
}
.subscribe-check-wrap svg {
  width: 24px !important;
  height: 24px !important;
  padding: 8px;
  margin-left: -8px;
}
.subscribe-check-wrap p {
  margin: 0 0 0 8px;
}

@media only screen and (min-width: 600px) {
  #contact form {
    grid-template-columns: 1fr 1fr;
    max-width: 948px;
    margin-top: 36px;
    grid-gap: 8px;
  }
  #subject,
  #text {
    grid-column: 1 / span 2;
  }
  #contact form input,
  #contact form textarea {
    padding: 16px 12px;
    font-size: 14px;
  }
}
