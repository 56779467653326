/* Font----------- */
h2 {
  font-size: 20px;
  color: #2a46ff;
}

p {
  font-size: 1rem;
  color: #838a96;
  line-height: 27px;
}

.App {
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.App div {
  box-sizing: border-box;
}

button {
  background: white;
  color: #2a46ff;
  font-weight: 700;
  font-size: 1rem;
  border: none;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
  outline: inherit;
  transition: 0.2s ease;
}
button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09),
    0px 15px 25px rgba(0, 0, 0, 0.12);
}
section > *:last-child {
  width: 100%;
  position: relative;
  z-index: 10;
  padding: 24px 16px;
  box-sizing: border-box;
}
/* Animations------------------ */
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translatey(80px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-up-sml {
  0% {
    opacity: 0;
    transform: translatey(24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}

.float-in-place {
  animation: float-alt-c 7s ease-in-out infinite;
}
@keyframes float-alt-c {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(0px) rotate(-8deg);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    transform: translatey(-8px) rotate(8deg);
  }

  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(0px) rotate(-8deg);
  }
}

/* @keyframes pop-ip {
  0% {
    opacity: 0;

    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

/* Flexbox--------------------- */
.flex-just-cent {
  display: flex;
  justify-content: center;
}
.flex-just-btw {
  display: flex;
  justify-content: space-between;
}
.flex-just-rit {
  display: flex;
  justify-content: flex-end;
}
.flex-just-lft {
  display: flex;
  justify-content: flex-start;
}
.flex-align-cent {
  display: flex;
  align-items: center;
}
.flex-align-top {
  display: flex;
  align-items: flex-start;
}

/* mobile------------------------------- */
.desktop-only {
  display: none;
}

.mobile-only {
  display: flex;
}

/* Tablet---- */
@media only screen and (min-width: 600px) {
  section > *:last-child {
    max-width: 800px;
    padding: 36px 16px;
  }

  /* mobile------------------------------- */
  .desktop-only {
    display: flex;
  }

  .mobile-only {
    display: none;
  }
}
/* Desktop---- */
@media only screen and (min-width: 900px) {
  section > *:last-child {
    max-width: 1400px;
    padding: 64px 36px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
  }
}
