#about {
  text-align: left;
}
.about-content-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
}

.about-content-wrapper-media {
  position: relative;
}

.about-content-wrapper-media img {
  width: 66%;
  border-radius: 20px;
}
.about-profile-dots {
  display: none;
}

.about-content-wrapper-text {
  max-width: 600px;
}

.about-content-wrapper-text > * {
  opacity: 0;
}

.about-text-show > * {
  /* font-size  */
  animation: fade-in-up 0.45s cubic-bezier(0.34, 0.8, 0.64, 1) forwards;
}
.about-text-show > *:nth-child(1) {
  animation-delay: 75ms;
}
.about-text-show > *:nth-child(2) {
  animation-delay: 120ms;
}
.about-text-show > *:nth-child(3) {
  animation-delay: 150ms;
}
.about-text-show > *:nth-child(4) {
  animation-delay: 162ms;
}
.about-text-show > *:nth-child(5) {
  animation-delay: 168ms;
}

.about-img {
  transform: scale(0.9);
  transition: 0.45s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.about-image-show {
  /* animation: pop-out 0.75s cubic-bezier(0.34, 1.56, 0.64, 1) forwards; */
  transform: scale(1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09), 0px 10px 20px rgba(0, 0, 0, 0.12);
}

.about-profile-title,
.about-profile-title h1 {
  font-size: 38px;
  color: #06152c;
  margin: 0;
  font-weight: 700;
  line-height: normal;
  display: block;
}
.about-profile-title h1 {
  display: inline;
}

.about-tags-wrapper {
  max-width: 900px;
}

.about-background-icons {
  position: absolute;
  top: -2.5%;
  right: -2.5%;
  z-index: -1;
  height: 105%;
  opacity: 0.5;
}

@media only screen and (min-width: 600px) {
  .about-content-wrapper {
    grid-template-columns: 30% 1fr;
    grid-gap: 24px;

    margin-bottom: 54px;
  }
  .about-content-wrapper-media img {
    width: 100%;
  }
  .about-profile-dots {
    display: block;
    position: absolute;
    top: 14vw;
    margin-left: 16px;
  }
}

@media only screen and (min-width: 900px) {
  .about-content-wrapper {
    grid-gap: 36px;
  }
  .about-profile-title,
  .about-profile-title h1 {
    font-size: 46px;
  }
  .about-profile-dots {
    top: 20vw;
    left: -24px;
    margin-left: 16px;
  }
}

@media only screen and (min-width: 1200px) {
  .about-background-icons {
    opacity: 1;
  }
  .about-profile-dots {
    top: auto;
    bottom: 12px;
    /* bottom: -32px; */
    left: -32px;
  }
}
@media only screen and (min-width: 1450px) {
  .about-profile-dots {
    bottom: 12px;
    /* bottom: -32px; */
    left: -48px;
  }
}
