.dot-wrapper {
  z-index: -1;
}
.dot-wrapper > *:not(:last-child) {
  margin-bottom: 16px;
}

.dot-row > *:not(:last-child) {
  margin-right: 16px;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 100%;
}
