.about-tag {
  padding: 6px 12px 8px;
  position: relative;
  border-radius: 10px;
  margin: 0 8px 8px 0;
  display: inline-block;
  border: 1px solid #838a96;
  color: #838a96;
  cursor: help;
}
.about-tag:hover {
  background: rgba(157, 165, 175, 0.1);
}

.tag-tool-tip {
  position: absolute;
  top: Calc(100% + 6px);
  padding: 8px;
  left: 0;
  border-radius: 10px;
  background: rgba(6, 21, 44, 0.9);
  color: white;
  font-size: 12px;
  z-index: 80;
  animation: drop-down 0.3s forwards cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  display: none;
  max-width: 200px;
  box-sizing: border-box;
}

.about-tag:hover .tag-tool-tip {
  display: block;
}

@keyframes drop-down {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.about-tag h1 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.about-primary-tag {
  border-color: #2a46ff;
  color: #2a46ff;
}
.about-primary-tag:hover {
  background: rgba(43, 71, 255, 0.1);
}

.no-outline {
  border-width: 0;
}
.no-tooltip,
.no-outline {
  cursor: context-menu;
}
.no-outline h1 {
  font-weight: 400;
}
.no-outline:hover {
  background: white;
}

@media only screen and (min-width: 750px) {
  .about-tag {
    /* margin: 0 12px 12px 0; */
  }
}
