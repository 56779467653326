.landing-mobile-wrap {
  display: none;
}

.landing-Mobile-pannel {
  width: 100%;
  display: block;
  border-color: #d1d7fc;
  box-shadow: 0px 8px 16px rgba(5, 31, 203, 0.05),
    0px 8px 16px rgba(43, 71, 255, 0.2);
}
@media only screen and (min-width: 770px) {
  .landing-mobile-wrap {
    display: block;
    position: absolute;
    width: 30%;
    z-index: 85;
    width: 22%;
    margin-top: 18%;
    margin-left: 0;
    right: 11.5%;
  }
}

@media only screen and (min-width: 1441px) {
  .landing-mobile-wrap {
    width: 25%;
    margin-top: 16%;
    right: 10%;
  }
}
