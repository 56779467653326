.mobile-dropdown-wrapper {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.2s ease-in-out;
  z-index: 110;
  cursor: pointer;
  animation: fadein 0.1s ease-in forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mobile-dropdown-wrapper nav {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
  flex-direction: column;
  box-sizing: border-box;
  z-index: 120;
  padding: 12px;

  cursor: auto;
  animation: dropdown 0.2s ease-in-out forwards;
}
@keyframes dropdown {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.mobile-dropdown-wrapper.nav-extended {
  display: block;
}
.mobile-dropdown-wrapper.nav-extended nav {
  display: flex;
}
.mobile-dropdown-wrapper nav a {
  color: #2a46ff;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 4px;
  text-decoration: none;
  font-weight: 600;
  transition: 0.1s ease-in-out;
}

.mobile-dropdown-wrapper nav a:hover {
  background: rgba(58, 85, 254, 0.1);
}

a.special-header-link-selected {
  background: #2a46ff;
  color: white !important;
}
a.special-header-link-selected:hover {
  background: rgba(58, 85, 254, 0.9) !important;
}
