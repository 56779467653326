.clients-logos-wrap {
  padding: 34px 0;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
}

.clients-logos-wrap > *:not(:last-child) {
  margin: 12px 24px 0 0;
}

.clients-logos-wrap a > * {
  height: 80px;
}
.clients-logos-wrap a {
  transition: 0.2s ease-in-out;
}
.clients-logos-wrap a:hover {
  opacity: 0.7;
}
.client-logo-hint {
  margin: auto;
  color: white;
  grid-gap: 8px;
  opacity: 0.5;
  cursor: default;
  font-style: italic;
  width: 100%;
}
.client-logo-hint svg {
  width: 24px;
}

@media only screen and (min-width: 600px) {
  .clients-logos-wrap {
    padding: 56px 0;
  }
  .clients-logos-wrap > *:not(:last-child) {
    margin: 24px 54px 0 0;
  }
  .clients-logos-wrap a > * {
    height: 96px;
  }
}

@media only screen and (min-width: 1400px) {
  .clients-logos-wrap a > * {
    height: 120px;
  }
}
