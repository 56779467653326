.section-header-wrap {
  text-align: center;
  width: auto;
  flex-direction: column;
  color: #06152c;
}

.section-header-wrap span,
.section-header-wrap h1 {
  display: inline;
  font-size: 38px;
  margin: 0;
  font-weight: 700;
  line-height: normal;
}

.about-profile-title h1 {
  display: inline;
}
.section-header-wrap div {
  width: 36px;
  border-bottom: 4px solid #06152c;
  margin-top: 12px;
}
.section-header-wrap h1 {
  color: rgb(42, 70, 255);
}

.blue-section .section-header-wrap,
.blue-section .section-header-wrap div {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}

.blue-section .section-header-wrap h1 {
  color: white;
}

@media only screen and (min-width: 900px) {
  .section-header-wrap span,
  .section-header-wrap h1 {
    font-size: 46px;
  }
}
