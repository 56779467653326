.landing-header-wrapper {
  position: relative;
  margin-top: 0;
  z-index: 99;
  max-width: 550px;
}

.landing-header-wrapper h3 {
  font-weight: 700;
  font-size: 38px;
  line-height: 110%;
  text-transform: capitalize;
  color: #ffffff;
  margin: 16px 0 16px 0;
}
.landing-header-wrapper h2 {
  font-weight: 400;
  font-size: 22px;
  max-width: 460px;
  margin: 0 0 24px 0;
  text-transform: capitalize;
  color: #ffffff;
}

span.landing-header-project {
  display: block;
  animation: slideinleft 0.1s ease-in forwards;
}

@keyframes slideinleft {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.blue-shadow {
  text-shadow: 0px 4px 4px rgba(43, 71, 255, 0.3);
}
.landing-dots-1 {
  position: absolute;
  bottom: -72px;
  right: -48px;
}

.landing-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}

.landing-buttons button {
  box-shadow: 0px 4px 4px rgba(43, 71, 255, 0.3);
  white-space: nowrap;
  font-size: 16px;
}

button.button-no-back {
  background: none;
  color: white;
  box-shadow: none;
  text-shadow: 0px 4px 4px rgba(43, 71, 255, 0.3);
}
button.button-no-back:hover {
  background: rgba(255, 255, 255, 0.1);
}

@media only screen and (min-width: 500px) {
  .landing-header-wrapper {
    margin-top: 56px;
  }
  .landing-header-wrapper h3 {
    font-size: 53px;
    /* margin: 0 0 2px 0; */
  }
  .landing-header-wrapper h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 36px 0;
  }
  .landing-buttons {
    margin-top: 24px;
    gap: 16px;
  }
  .landing-buttons button {
    height: 56px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 770px) {
  .landing-header-wrapper {
    position: absolute;
    left: 10%;
    margin-top: -80px;
  }
  .landing-header-wrapper h3 {
    font-size: 64px;
    margin: 0 0 26px 0;
  }
  .landing-header-wrapper h2 {
    font-size: 26px;
    margin: 0;
  }
  .landing-buttons {
    margin-top: 130px;
    justify-content: flex-start;
  }
}
