.landing-background-text-wrap {
  height: 100%;
  padding: 10% 0;
  box-sizing: border-box;
}

.landing-background-text {
  position: absolute;
  z-index: 5;
  left: -4vh;
  margin: 0;

  font-family: "Oswald", sans-serif;
  white-space: nowrap;
  font-size: min(23vh, 256px);
  line-height: 100%;
  text-transform: uppercase;
  text-align: left;
  color: white;
  opacity: 0.1;
  user-select: none;
}
