section {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
  text-align: left;
}

section.fullscreen-section {
  min-height: min(100vh, 1200px);
  height: 100%;
}

section.blue-section,
footer {
  background: linear-gradient(104.93deg, #3a55fe 9.86%, #2a46ff 84.3%);
}
