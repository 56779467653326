.scroll-arrow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.2s ease-in forwards;
  z-index: 7;
}

.scroll-arrow svg {
  animation: gental-nod 1.25s ease-in-out infinite alternate;
}
@keyframes gental-nod {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-8px);
  }
}
