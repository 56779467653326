button.mobile-menu-button {
  background: none;
  padding: 8px;
  /* margin-left: -4px; */
  box-sizing: border-box;
}
section button.mobile-menu-button:hover {
  background: rgba(42, 70, 255, 0.1);
  box-shadow: none;
}
section.blue-section button.mobile-menu-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

section header #header-mobile-menu path {
  fill: #2a46ff;
}
section.blue-section header #header-mobile-menu path {
  fill: white;
}
