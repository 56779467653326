footer {
  padding: 16px 0;
  color: white;
}
footer > * {
  flex-direction: column-reverse;
  grid-gap: 16px;
}
.footer-social-links > *:not(:last-child) {
  margin-right: 4px;
}
footer a {
  padding: 6px;
  border-radius: 10px;
  display: block;
}
footer a:hover {
  background: rgba(255, 255, 255, 0.1);
}
footer svg {
  width: 24px;
  height: 24px;
  margin: 0;
}
footer svg path {
  fill: white;
}
@media only screen and (min-width: 600px) {
  footer > * {
    flex-direction: row;
  }
}
