.profile-title-white,
.profile-title-white h1 {
  font-size: 38px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  font-weight: 700;
  line-height: normal;
}

.profile-titleh1 {
  display: inline;
}

#portfolio .social-wrapper {
  margin: 16px 0 48px 4px;
}
.show-socials > * {
  opacity: 0;
  animation: fade-in-up-sml 0.45s cubic-bezier(0.34, 0.8, 0.64, 1) forwards;
}

.show-socials > *:nth-child(1) {
  animation-delay: 0;
}
.show-socials > *:nth-child(2) {
  animation-delay: 75ms;
}
.show-socials > *:nth-child(3) {
  animation-delay: 120ms;
}

.social-wrapper > *:not(:last-child) {
  margin-right: 21px;
}
.social-wrapper a {
  border-radius: 10px;
  padding: 6px;
  margin: -6px;
}
.social-wrapper svg {
  width: 32px;
  height: 32px;
  display: block;
}
.social-wrapper svg path {
  fill: #2a46ff;
}
.social-wrapper a:hover {
  background: rgba(43, 71, 255, 0.1);
}

section.blue-section .social-wrapper svg path {
  fill: white;
}
section.blue-section .social-wrapper a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.portfolio-dots-1 {
  position: absolute;
  bottom: -54px;
  left: 18px;
}

.portfolio-dots-2 {
  position: absolute;
  top: 28%;
  left: 70%;
}

.portfolio-button-wrap {
  padding-top: 24px;
  margin-bottom: -16px;
}

.portfolio-button-wrap button {
  background: none;
  color: white;
  transition: 0.15s ease-in-out;
  box-shadow: none !important;
}

.portfolio-button-wrap button svg {
  width: 24px;
  height: 24px;
  margin: 0 -8px 0 8px;
}

.portfolio-button-wrap button:hover {
  background: rgba(255, 255, 255, 0.1);
}
@media only screen and (min-width: 600px) {
  .portfolio-dots-2 {
    bottom: 50%;
    left: 50%;
  }
  .portfolio-dots-1 {
    position: absolute;
    bottom: -54px;
    left: 100px;
  }
}

@media only screen and (min-width: 900px) {
  .profile-title,
  .profile-title h1 {
    font-size: 46px;
  }
  #portfolio .social-wrapper {
    margin-bottom: 80px;
  }
}
