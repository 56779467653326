.landing-media-wrap {
  margin-top: -24px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 56px;
  top: 0;
  left: 0;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  text-align: center;
}

.blur-background {
  display: block;
  background: linear-gradient(
    110.62deg,
    rgba(255, 255, 255, 0.25) 6.39%,
    rgba(255, 255, 255, 0.05) 53.34%
  );
  backdrop-filter: blur(40px);
  overflow: hidden;
  border: 1px solid #7d8efc;
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
  box-shadow: 0px 8px 16px rgba(5, 31, 203, 0.05);
  border-radius: 8px;
}
.blur-background img {
  width: 100%;
  display: block;
}

@media only screen and (min-width: 500px) {
  .landing-media-wrap {
    margin-top: 0;
  }
  .blur-background {
    border-radius: 12px;
  }
}

@media only screen and (min-width: 770px) {
  .landing-media-wrap {
    flex-direction: row;
    align-items: center;

    text-align: left;
  }
  .blur-background {
    border-radius: 20px;
    border-width: 2px;
  }
}

@media only screen and (min-width: 1441px) {
  .blur-background {
    border-radius: 28px;
    border-width: 3px;
  }
}
