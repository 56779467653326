/* .landing-desktop-wrap {
  display: none;
}
@media only screen and (min-width: 500px) { */

.landing-media-wrap > * img {
  width: 100%;
}
.landing-desktop-wrap {
  width: 80vw;
  position: relative;
  display: block;
  margin-bottom: 24px;
}
.landing-desktop-pannel {
  position: relative;
  width: 80%;
  z-index: 80;
  animation: float-alt-a 10s ease-in-out infinite;
}

.mobile-landing-graph {
  display: block;
}
.desktop-landing-graph {
  display: none;
}

.mobile-landing {
  width: 30%;
  position: absolute;
  left: 67%;
  bottom: -10%;
  animation: float-alt-b 12s ease-in-out infinite;

  z-index: 85;
}

.landing-desktop-wrap {
  width: 100%;
}
svg#desktop-pazzaze {
  display: none;
}

.desktop-pazzaze-wrapper svg > *:nth-child(1) {
  animation: float-alt-c 8s ease-in-out infinite;
  animation-delay: -1s;
}
.desktop-pazzaze-wrapper svg > *:nth-child(2) {
  animation: float-alt-a 6s ease-in-out infinite;
  animation-delay: 0s;
}
.desktop-pazzaze-wrapper svg > *:nth-child(3) {
  animation: float-alt-c 7s ease-in-out infinite;
  animation-delay: 1s;
}
.desktop-pazzaze-wrapper svg > *:nth-child(4) {
  animation: float-alt-a 8s ease-in-out infinite;
  animation-delay: 0.5s;
}
.desktop-pazzaze-wrapper svg > *:nth-child(5) {
  animation: float-alt-c 6s ease-in-out infinite;
  animation-delay: -0.5s;
}
.desktop-pazzaze-wrapper svg > *:nth-child(6) {
  animation: float-alt-a 7s ease-in-out infinite;
  animation-delay: 0s;
}
.desktop-pazzaze-wrapper svg > *:nth-child(7) {
  animation: float-alt-c 8s ease-in-out infinite;
  animation-delay: -1s;
}
.desktop-pazzaze-wrapper svg > *:nth-child(8) {
  animation: float-alt-a 6s ease-in-out infinite;
  animation-delay: 0s;
  fill: #fff !important;
}
.desktop-pazzaze-wrapper svg > *:nth-child(9) {
  animation: float-alt-c 7s ease-in-out infinite;
  animation-delay: 1s;
}
.desktop-pazzaze-wrapper svg > *:nth-child(10) {
  animation: float-alt-a 6s ease-in-out infinite;
  animation-delay: 0s;
  fill: #fff !important;
}
.desktop-pazzaze-wrapper svg > *:nth-child(11) {
  animation: float-alt-c 8s ease-in-out infinite;
  animation-delay: -1s;
}
.desktop-pazzaze-wrapper svg > *:nth-child(12) {
  animation: float-alt-a 6s ease-in-out infinite;
  animation-delay: 0s;
}

@keyframes float-alt-a {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(2px) translatex(-2px) rotate(-1deg);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    transform: translatey(-2px) translatex(2px) rotate(1deg);
  }

  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(2px) translatex(-2px) rotate(-1deg);
  }
}
@keyframes float-alt-b {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(12px) translatex(-6px) rotate(1deg);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    transform: translatey(-12px) translatex(6px) rotate(-1deg);
  }

  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(12px) translatex(-6px) rotate(1deg);
  }
}
@keyframes float-alt-c {
  0% {
    opacity: 1;
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(8px) translatex(-8px) rotate(-1deg);
  }
  50% {
    opacity: 0.1;
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    transform: translatey(-8px) translatex(8px) rotate(1deg);
  }

  100% {
    opacity: 1;
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(8px) translatex(-8px) rotate(-1deg);
  }
}

/* } */

@media only screen and (min-width: 500px) {
  svg#desktop-pazzaze {
    /* display: block; */
    position: absolute;
    z-index: 75;
    left: 0;
    top: -10%;
    width: 110%;
    height: 120%;
    opacity: 0.7;
  }
}

@media only screen and (min-width: 770px) {
  .landing-desktop-wrap {
    position: absolute;
    z-index: 80;
    width: 70%;
    margin-top: 0%;
    right: 6%;
    margin-bottom: 0;
  }

  .mobile-landing-graph {
    display: none;
  }
  .desktop-landing-graph {
    display: block;
  }

  .landing-desktop-pannel {
    width: 90%;
    right: -10%;
    /* animation: none; */
    animation: float-alt-a 8s ease-in-out infinite;
    /* animation-delay: 1s; */
  }

  .mobile-landing {
    width: 30%;
    position: absolute;
    left: 60%;
    bottom: -10%;

    z-index: 85;
  }

  svg#desktop-pazzaze {
    position: absolute;
    z-index: 75;
    left: 8.5%;
    top: -6%;
    width: 96.5%;
    height: 120%;
  }
  @keyframes float-alt-b {
    0% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(12px) translatex(-6px) rotate(1deg);
    }
    50% {
      /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
      transform: translatey(-12px) translatex(6px) rotate(-1deg);
    }

    100% {
      /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
      transform: translatey(12px) translatex(-6px) rotate(1deg);
    }
  }
}

@media only screen and (min-width: 1441px) {
  .landing-desktop-wrap {
    width: 80%;
    margin-top: -5%;
    right: 5%;
  }
}
