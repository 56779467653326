.portfolio-card {
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
  border-radius: 12px;
  width: 200px;
  height: 156px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s cubic-bezier(0.34, 0.8, 0.64, 1);
  display: block;
}
.portfolio-card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

.portfolio-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 600px) {
  .portfolio-card {
    border-radius: 20px;
    width: 300px;
    height: 234px;
  }
}
