.slideshow {
  /* position: relative; */
  position: absolute;
  left: 0;
  width: 100vw;
  z-index: 50;
  border: 1px solid red;
  padding: 0;
}
.slide {
  margin-right: 16px;
}

.portfolio-card {
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
  border-radius: 12px;
  width: 200px;
  height: 156px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s cubic-bezier(0.34, 0.8, 0.64, 1);
  display: block;
}
.portfolio-card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

.portfolio-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.3);
}

.flickity-button.flickity-prev-next-button {
  position: absolute;
  z-index: 60;
  cursor: pointer;
  padding: 10px !important;
  background: rgba(255, 255, 255, 0.1);
}
.flickity-button.flickity-prev-next-button:hover {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.5);
}

.flickity-button.flickity-prev-next-button svg {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0;
}
.flickity-button.flickity-prev-next-button svg path {
  fill: white;
}

.flickity-button.flickity-prev-next-button.previous {
}
.flickity-button.flickity-prev-next-button.next {
  right: 0;
}

@media only screen and (min-width: 600px) {
  .portfolio-card {
    border-radius: 20px;
    width: 300px;
    height: 234px;
  }
  .slide {
    margin-right: 24px;
  }
}
